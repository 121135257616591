import { defineStore } from 'pinia'
import axios from 'axios'
import { apiURL } from '@/main'

export const useSmpEncashmentEntityStore = defineStore('smpEncashmentEntity', {
  state: () => ({
    activeSmpEncashmentEntities: [],
    smpEncashmentEntities: [],
    loading: false,
    error: null as string | null
  }),

  actions: {
    async fetchActiveSmpEncashmentEntities() {
      this.loading = true
      this.error = null
      try {
        const response = await axios.get(apiURL + '/smpEncashmentEntities', { params: { onlyActive: true } })
        const items = await response.data
        Array.isArray(items) ? items : []
        this.activeSmpEncashmentEntities = response.data
      } catch (error) {
        this.error = 'Failed to fetch encashment organisations'
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async fetchSmpEncashmentEntities() {
      this.loading = true
      this.error = null
      try {
        const response = await axios.get(apiURL + '/smpEncashmentEntities', { params: { onlyActive: false } })
        const items = await response.data
        Array.isArray(items) ? items : []
        this.smpEncashmentEntities = response.data
      } catch (error) {
        this.error = 'Failed to fetch encashment organisations'
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
})
